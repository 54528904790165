<template>
  <div class="library-section-wrapper">
    <LibraryHeader :mentor="profile" />
    <LibraryFilters
      :is-mentor="profile.type === 'mentor'"
      @onFilter="filterRecords"
    />
    <b-container class="px-5 px-sm-0 py-7 py-sm-9">
      <b-row
        align-h="center"
        class="justify-content-lg-between pb-lg-9"
        no-gutters
      >
        <!-- <b-col class="pr-lg-5 mt-9 mt-lg-0" cols="12" lg="4" order="2" order-lg="1" xl="3">
          <b-row align-h="between" class="flex-lg-column justify-content-lg-start" no-gutters>
            <b-col class="w-100 pr-sm-4 pr-lg-0" cols="12" sm="6" lg="auto">
              <SessionMenu :profile="profile" @search="search" />
            </b-col>
            <b-col v-if="0" class="w-100 pl-sm-4 pl-lg-0" cols="12" sm="6" lg="auto">
              <RecommendedMentors />
            </b-col>
          </b-row>
        </b-col> -->
        <b-col class="pl-lg-1" cols="12" lg="12" order="1" order-lg="2" xl="12">
          <LibraryContents :is-mentor="profile.type === 'mentor'" />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import Vue from "vue";
import { mapGetters, mapState } from "vuex";
import LibraryHeader from "@/components/Library/LibraryHeader";
import LibraryFilters from "@/components/Library/LibraryFilters";
import LibraryContents from "@/components/Library/LibraryContents";
// import SessionMenu from "@/components/Session/SessionMenu.vue";
// import RecommendedMentors from "@/components/Mentor/RecommendedMentors.vue";

export default Vue.extend({
  name: "MentoringPlanPage",
  components: {
    // RecommendedMentors,
    // SessionMenu,
    LibraryHeader,
    LibraryFilters,
    LibraryContents
  },
  computed: {
    ...mapGetters({
      profile: "profile/getProfile"
    }),
    ...mapState("library_content", ["filters"])
  },

  async mounted() {
    await this.$store.dispatch("library_content/GET_ALL_CONTENT");
  },
  methods: {
    search(data) {
      this.filters.search = data.searchVal;
      this.filterRecords();
      /*console.log(data.searchVal, this.filters);*/
    },
    async filterRecords() {
      await this.$store.dispatch("library_content/GET_ALL_CONTENT");
    }
  }
});
</script>
